<template>
  <div>
    <el-row :gutter="20" style="margin-bottom: 10px;">
      <el-col :span="12">
        <div class="dashboard-card">
          <g2-plot :option="monthlyOption" type="Line" />
        </div>
      </el-col>

      <el-col :span="12">
        <div class="dashboard-card">
          <g2-plot :option="efficiencyOption" type="StackedBar" />
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <div class="dashboard-card">
          <g2-plot :option="deploymentAllocationOption" type="Pie" />
        </div>
      </el-col>

      <el-col :span="12">
        <div class="dashboard-card">
          <g2-plot :option="productAllocationOption" type="Pie" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      monthlyCost: [],
      monthlyOption: {
        title: {
          visible: true,
          text: "Monthly cluster costs"
        },
        description: {
          visible: true,
          text: "Monthly run rate expenses based on resource prices"
        },
        padding: "auto",
        forceFit: true,
        data: [
          { month: "Jan", value: 2200 },
          { month: "Feb", value: 2300 },
          { month: "Mar", value: 2000 },
          { month: "Apr", value: 2500 },
          { month: "May", value: 2000 },
          { month: "Jun", value: 2800 },
          { month: "July", value: 2600 }
        ],
        xField: "month",
        yField: "value",
        point: {
          visible: true
        },
        label: {
          visible: true,
          type: "point"
        }
      },

      efficiencyOption: {
        forceFit: true,
        title: {
          visible: true,
          text: "Resource Efficiency"
        },

        description: {
          visible: true,
          text: "Based on currently provisioned resources and last 24h utilization"
        },

        data: [
          {
            type: "Compute",
            usage: "Idle",
            count: 3200
          },
          {
            type: "Compute",
            usage: "System",
            count: 50
          },
          {
            type: "Compute",
            usage: "Apps",
            count: 50
          },
          {
            type: "Compute",
            usage: "Other",
            count: 50
          },

          {
            type: "Memory",
            usage: "Idle",
            count: 1800
          },
          {
            type: "Memory",
            usage: "System",
            count: 10
          },
          {
            type: "Memory",
            usage: "Apps",
            count: 50
          },
          {
            type: "Memory",
            usage: "Other",
            count: 10
          },

          {
            type: "Storage",
            usage: "Idle",
            count: 0
          },
          {
            type: "Storage",
            usage: "System",
            count: 0
          },
          {
            type: "Storage",
            usage: "Apps",
            count: 50
          },
          {
            type: "Storage",
            usage: "Other",
            count: 0
          }
        ],
        yField: "type",
        xField: "count",
        stackField: "usage",

        meta: {
          count: {
            alias: "Cost",
            formatter: v => {
              return `${v}$`;
            }
          }
        }
      },

      deploymentAllocationOption: {
        forceFit: true,
        title: {
          visible: true,
          text: "Deployment Allocation"
        },

        radius: 0.8,
        data: [
          {
            name: "angelia/angelia-backend",
            value: 5.3
          },
          {
            name: "default/baget",
            value: 5
          },
          {
            name: "default/kubestar-docs",
            value: 5
          },
          {
            name: "default/kubestar-official-site",
            value: 4
          },
          {
            name: "default/kubestar",
            value: 10
          }
        ],
        angleField: "value",
        colorField: "name",
        label: {
          visible: true,
          type: "outer",
          offset: 20
        }
      },

      productAllocationOption: {
        forceFit: true,
        title: {
          visible: true,
          text: "Product Allocation"
        },

        radius: 0.8,
        data: [
          {
            name: "__unallocated__",
            value: 5.3
          },
          {
            name: "coredns",
            value: 5
          },
          {
            name: "kubestar-docs",
            value: 5
          },
          {
            name: "cadvisor",
            value: 4
          },
          {
            name: "amazonsnswrapperapi",
            value: 8
          },

          {
            name: "customer-portal",
            value: 6
          },

          {
            name: "angelia-frontend",
            value: 4
          }
        ],
        angleField: "value",
        colorField: "name",
        label: {
          visible: true,
          type: "outer",
          offset: 20
        }
      }
    };
  },

  methods: {}
};
</script>

<style></style>
